import React from 'react'
import { CSSProperties, StylePropertyValue } from 'theme-ui'

interface Props {
  heading: string
  subHeading?: string
  titleSubHeading?: string
  image?: any
  bgColor?: string
  restrictText?: boolean
  cta?: any
  reverse?: boolean
  theme?: 'dark' | 'light'
  align?: 'flex-start' | 'flex-end' | 'center'
}

const BasicHero = ({
  image,
  reverse,
  heading,
  subHeading,
  bgColor,
  restrictText,
  cta,
  theme,
  align,
  titleSubHeading,
}: Props) => {
  return (
    <section
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: ['center'],
        height: ['100%', null, null, 600],
        bg: bgColor || 'brand.veniceBlue',
        pt: [100, null, null, 3],
      }}
    >
      <div
        sx={{
          maxWidth: ['100%', null, null, 1200],
          width: '100%',
          px: [4, null, null, null, 0],
          zIndex: 2,
          color: 'white',
          position: 'relative',
          display: 'flex',
          flexDirection: ['column'],
          justifyContent: 'center',
          alignItems: [
            'center',
            null,
            null,
            reverse ? 'flex-end' : align ? align : 'flex-start',
          ],
        }}
      >
        <div
          sx={{
            position: 'relative',
            zIndex: 2,
          }}
        >
          <h1
            sx={{
              fontSize: [40, null, null, 68],
              lineHeight: 1.29411,
              zIndex: 2,
              mb: !cta && !image && !subHeading && !titleSubHeading ? 5 : 0,
              position: 'relative',
              maxWidth: restrictText ? 700 : 'auto',
              color: theme === 'light' ? 'black' : 'white',
            }}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {titleSubHeading && (
            <p
              sx={{
                maxWidth: 480,
                lineHeight: '25px',
                fontSize: 22,
                pb: [cta ? 2 : 5, null, null, 0],
                color: 'brand.curiousBlue',
              }}
              dangerouslySetInnerHTML={{ __html: titleSubHeading }}
            />
          )}
          {subHeading && (
            <p
              sx={{
                maxWidth: 480,
                lineHeight: '25px',
                fontSize: 18,
                color: theme === 'light' ? 'black' : 'white',
              }}
              dangerouslySetInnerHTML={{ __html: subHeading }}
            />
          )}
          {cta && cta}
        </div>

        <div
          sx={
            reverse
              ? {
                  zIndex: 1,
                  position: ['relative', null, null, 'absolute'],
                  left: 0,
                  top: 0,
                }
              : {
                  zIndex: 1,
                  position: ['relative', null, null, 'absolute'],
                  right: 0,
                  top: 0,
                }
          }
        >
          {image && image}
        </div>
      </div>
    </section>
  )
}

export default BasicHero
