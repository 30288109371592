import React from 'react'
import Logo from '../components/Logo'
import { Container, Flex } from 'theme-ui'
import { Link } from 'gatsby'
import Nav from '../components/Nav'
import { StaticImage } from 'gatsby-plugin-image'

interface Props {
  theme?: 'dark' | 'light'
}

const Header = ({ theme }: Props) => {
  const [scrolled, setScrolled] = React.useState(false)
  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <Flex
      as="nav"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        bg: scrolled ? 'brand.veniceBlue' : 'transparent',
        position: [scrolled ? 'fixed' : 'absolute'],
        width: '100%',
        top: [0, null, null, scrolled ? 0 : 0],
        left: ['0', null, null, 0],
        zIndex: 99,
        px: [0, null, null, 0, 5],
        transition: 'all 0.5s ease',
      }}
    >
      <Container
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          py: ['20px!important'],
        }}
      >
        <div
          sx={{
            position: 'relative',
            m: [1, null, null, 0],
          }}
        >
          <Logo
            size={scrolled ? 140 : 180}
            type={scrolled ? 'white' : theme === 'dark' ? 'white' : 'original'}
          />
        </div>
        <Nav theme={theme} scrolled={scrolled} />
      </Container>
    </Flex>
  )
}

export default Header
