import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import HorizontalLogo from './HorizontalLogo'

interface Props {
  type: 'white' | 'original'
  size?: number
}

const Logo = ({ type = 'original', size = 200 }: Props) => {
  return (
    <Link to="/">
      <HorizontalLogo
        type={type}
        size={size}
        sx={{
          transition: 'all 0.5s ease',
        }}
      />
    </Link>
  )
}

export default Logo
