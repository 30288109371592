import React from 'react'

interface Props {
  type: 'white' | 'original'
  className?: string
  size?: number
}

const HorizontalLogo = ({ type, className, size }: Props) => {
  const svgRef = React.useRef<SVGSVGElement>(null)
  const [viewBoxSize, setViewBoxSize] = React.useState<string>('0 0 0 0')

  React.useEffect(() => {
    if (!svgRef.current) return
    const { x, y, width, height } = svgRef.current.getBBox()
    setViewBoxSize([x - 1, y - 1, width + 2, height + 2].join(' '))
  }, [])

  return type == 'white' ? (
    <svg
      ref={svgRef}
      width={size}
      viewBox={viewBoxSize}
      sx={{
        '.cls-1': {
          fill: '#fff',
        },
        transition: 'all 0.5s ease',
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <polygon
            className="cls-1"
            points="175.55 117.58 174.89 114.38 161.8 114.38 161.8 138.73 165.27 138.73 165.27 128.56 173.5 128.56 173.5 125.36 165.27 125.36 165.27 117.58 175.55 117.58"
          />
          <polygon
            className="cls-1"
            points="187.04 138.73 190.51 138.73 190.51 114.07 187.04 114.68 187.04 138.73"
          />
          <polygon
            className="cls-1"
            points="221.72 131.89 203.33 112.8 203.33 138.73 206.8 138.73 206.8 121.22 225.19 140.31 225.19 114.04 221.72 114.69 221.72 131.89"
          />
          <path
            className="cls-1"
            d="M235.17,138.73h3.73l3.66-8.15h8.94l3.66,8.15h3.73l-11.86-25.72-11.86,25.72ZM247.03,120.69l3.06,6.75h-6.11l3.05-6.75Z"
          />
          <polygon
            className="cls-1"
            points="287.25 131.89 268.86 112.8 268.86 138.73 272.34 138.73 272.34 121.22 290.72 140.31 290.72 114.04 287.25 114.69 287.25 131.89"
          />
          <path
            className="cls-1"
            d="M321.8,134.11c-1.99,1.25-3.92,1.83-6.08,1.83-5.5,0-9.8-4.12-9.8-9.38s4.31-9.38,9.8-9.38c2.04,0,4.08.58,5.76,1.62l.47.3.7-3.43-.27-.13c-2.07-1.03-4.29-1.56-6.6-1.56-7.56,0-13.49,5.53-13.49,12.58s5.89,12.58,13.41,12.58c2.48,0,4.83-.59,7-1.76l.25-.14-.7-3.42-.47.3Z"
          />
          <polygon
            className="cls-1"
            points="338.15 135.53 338.15 127.79 347.06 127.79 347.06 124.58 338.15 124.58 338.15 117.58 349 117.58 348.34 114.38 334.68 114.38 334.68 138.73 348.84 138.73 349.49 135.53 338.15 135.53"
          />
        </g>
        <g>
          <path
            className="cls-1"
            d="M132.19,93.82h5.8l9.13-21.48h20.74l9.14,21.48h5.88l-25.38-58.96-25.31,58.96ZM149.31,67.15l8.19-19.34,8.19,19.34h-16.38Z"
          />
          <path
            className="cls-1"
            d="M250.68,93.82h5.8l9.13-21.48h20.74l9.14,21.48h5.88l-25.38-58.96-25.3,58.96ZM267.79,67.15l8.19-19.34,8.19,19.34h-16.38Z"
          />
          <polygon
            className="cls-1"
            points="311.41 93.82 316.85 93.82 316.85 37.31 311.41 38.62 311.41 93.82"
          />
          <polygon
            className="cls-1"
            points="373.67 38.64 373.67 82.65 332.64 34.73 332.64 93.82 338.08 93.82 338.08 49.09 379.1 97.09 379.1 37.22 373.67 38.64"
          />
          <polygon
            className="cls-1"
            points="250.96 37.99 218.1 37.99 214.14 37.99 181.28 37.99 180.2 43.27 196.98 43.27 196.98 93.82 202.41 93.82 202.41 43.27 213.06 43.27 219.08 43.27 229.84 43.27 229.84 93.82 235.26 93.82 235.26 43.27 251.94 43.27 250.96 37.99"
          />
        </g>
      </g>
      <g>
        <polygon
          className="cls-1"
          points="53.56 78.82 69.58 93.91 53.58 78.79 53.56 78.82"
        />
        <polygon
          className="cls-1"
          points="77.28 101.21 77.2 101.11 69.58 93.91 53.56 78.82 33.57 135.08 33.57 135.25 74.31 154.33 113.39 135.3 77.3 101.2 77.28 101.21"
        />
        <polygon
          className="cls-1"
          points="97.83 85.26 113.52 132.23 114.16 72.47 98.9 84.36 98.93 84.4 97.83 85.26"
        />
        <polygon
          className="cls-1"
          points="34.35 60.73 33.61 132.1 52.79 78.1 34.35 60.73"
        />
        <polygon
          className="cls-1"
          points="85.59 93.53 97.03 84.62 74.73 17.67 53.59 77.47 77.36 99.93 85.59 93.53"
        />
        <polygon
          className="cls-1"
          points="78.07 100.6 112.93 133.53 97.02 85.88 78.07 100.6"
        />
      </g>
    </svg>
  ) : (
    <svg
      ref={svgRef}
      width={size}
      viewBox={viewBoxSize}
      sx={{
        '.cls-1': {
          fill: '#dfd3b8',
        },
        '.cls-2': {
          fill: '#174780',
        },
        '.cls-3': {
          fill: '#1d96d3',
        },
        '.cls-4': {
          fill: '#184a71',
        },
        '.cls-5': {
          fill: '#51779d',
        },
        '.cls-6': {
          fill: '#197c98',
        },
        transition: 'all 0.5s ease',
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <polygon
            className="cls-4"
            points="175.55 117.58 174.89 114.38 161.8 114.38 161.8 138.73 165.27 138.73 165.27 128.56 173.5 128.56 173.5 125.36 165.27 125.36 165.27 117.58 175.55 117.58"
          />
          <polygon
            className="cls-4"
            points="187.04 138.73 190.51 138.73 190.51 114.07 187.04 114.68 187.04 138.73"
          />
          <polygon
            className="cls-4"
            points="221.72 131.89 203.33 112.8 203.33 138.73 206.8 138.73 206.8 121.22 225.19 140.31 225.19 114.04 221.72 114.69 221.72 131.89"
          />
          <path
            className="cls-4"
            d="M235.17,138.73h3.73l3.66-8.15h8.94l3.66,8.15h3.73l-11.86-25.72-11.86,25.72ZM247.03,120.69l3.06,6.75h-6.11l3.05-6.75Z"
          />
          <polygon
            className="cls-4"
            points="287.25 131.89 268.86 112.8 268.86 138.73 272.34 138.73 272.34 121.22 290.72 140.31 290.72 114.04 287.25 114.69 287.25 131.89"
          />
          <path
            className="cls-4"
            d="M321.8,134.11c-1.99,1.25-3.92,1.83-6.08,1.83-5.5,0-9.8-4.12-9.8-9.38s4.31-9.38,9.8-9.38c2.04,0,4.08.58,5.76,1.62l.47.3.7-3.43-.27-.13c-2.07-1.03-4.29-1.56-6.6-1.56-7.56,0-13.49,5.53-13.49,12.58s5.89,12.58,13.41,12.58c2.48,0,4.83-.59,7-1.76l.25-.14-.7-3.42-.47.3Z"
          />
          <polygon
            className="cls-4"
            points="338.15 135.53 338.15 127.79 347.06 127.79 347.06 124.58 338.15 124.58 338.15 117.58 349 117.58 348.34 114.38 334.68 114.38 334.68 138.73 348.84 138.73 349.49 135.53 338.15 135.53"
          />
        </g>
        <g>
          <path
            className="cls-4"
            d="M132.19,93.82h5.8l9.13-21.48h20.74l9.14,21.48h5.88l-25.38-58.96-25.31,58.96ZM149.31,67.15l8.19-19.34,8.19,19.34h-16.38Z"
          />
          <path
            className="cls-4"
            d="M250.68,93.82h5.8l9.13-21.48h20.74l9.14,21.48h5.88l-25.38-58.96-25.3,58.96ZM267.79,67.15l8.19-19.34,8.19,19.34h-16.38Z"
          />
          <polygon
            className="cls-4"
            points="311.41 93.82 316.85 93.82 316.85 37.31 311.41 38.62 311.41 93.82"
          />
          <polygon
            className="cls-4"
            points="373.67 38.64 373.67 82.65 332.64 34.73 332.64 93.82 338.08 93.82 338.08 49.09 379.1 97.09 379.1 37.22 373.67 38.64"
          />
          <polygon
            className="cls-4"
            points="250.96 37.99 218.1 37.99 214.14 37.99 181.28 37.99 180.2 43.27 196.98 43.27 196.98 93.82 202.41 93.82 202.41 43.27 213.06 43.27 219.08 43.27 229.84 43.27 229.84 93.82 235.26 93.82 235.26 43.27 251.94 43.27 250.96 37.99"
          />
        </g>
      </g>
      <g>
        <polyline
          className="cls-1"
          points="114.16 72.74 33.57 135.52 74.31 154.59 113.48 135.52 114.16 72.74"
        />
        <polyline
          className="cls-5"
          points="34.35 61 113.48 135.52 74.31 154.59 33.57 135.52 34.35 61"
        />
        <polyline
          className="cls-3"
          points="74.73 19.13 53.54 79.07 77.31 101.45 97.03 86.09 74.73 19.13"
        />
        <polyline
          className="cls-6"
          points="97.03 86.09 77.31 101.45 113.48 135.52 97.03 86.09"
        />
        <polyline
          className="cls-2"
          points="53.54 79.07 33.57 135.52 74.31 154.59 113.48 135.52 77.31 101.45 53.54 79.07"
        />
      </g>
    </svg>
  )
}

export default HorizontalLogo
