import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import { Flex } from 'theme-ui'
import Logo from '../Logo'
interface Route {
  path: string
  name: string
}

interface Props {}
export const routes = [
  {
    path: '/credit-solutions',
    name: 'Credit Solutions',
  },
  {
    path: '/about',
    name: 'About',
  },
  {
    path: '/leadership',
    name: 'Leadership',
  },
  {
    path: '/careers',
    name: 'Careers',
  },
  {
    path: '/news',
    name: 'News',
  },
]
const Nav = ({
  theme,
  scrolled,
}: {
  theme?: 'dark' | 'light'
  scrolled: boolean
}) => {
  const [activeLink, setActiveLink] = React.useState('')
  useEffect(() => {
    const pathname = window.location.pathname
    setActiveLink(pathname)
  }, [])
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <Flex
      sx={{
        gap: 4,
        position: ['absolute', null, null, 'relative'],
        top: [isOpen ? 0 : 20, null, null, 0],
        right: 0,
      }}
    >
      <Flex
        sx={{
          gap: 3,
          display: ['none', null, null, 'flex'],
        }}
      >
        {routes.map((route: Route, i: number) => (
          <Link
            to={route.path}
            key={i}
            sx={{
              color: [
                scrolled
                  ? 'white'
                  : theme === 'dark'
                  ? route.path === activeLink
                    ? 'white'
                    : '#9DA1A1'
                  : route.path === activeLink
                  ? '#9DA1A1'
                  : 'black',
              ],
              fontWeight: 'medium',
              fontSize: [32, null, null, 16],
            }}
          >
            {route.name}
          </Link>
        ))}
      </Flex>
      <button
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          position: [isOpen ? 'fixed' : 'absolute', null, null, 'relative'],
          border: 'none',
          display: ['block', null, null, 'none'],
          zIndex: 10,
          top: isOpen ? 45 : 25,
          right: 30,
          bg: 'transparent',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="18"
          viewBox="0 0 29 18"
        >
          <g transform="translate(-54 1)">
            <g
              transform="translate(55)"
              sx={{
                line: {
                  stroke: scrolled
                    ? '#ffffff'
                    : isOpen
                    ? '#ffffff'
                    : theme === 'dark'
                    ? '#ffffff'
                    : '#1A1818',
                },
              }}
            >
              <line
                x2="27"
                transform="translate(0 0)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="2"
                sx={{
                  transform:
                    isOpen && 'rotate(45deg) translateY(-5px) translateX(5px)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
              <line
                x2="27"
                transform="translate(0 8)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="2"
                sx={{
                  transform:
                    isOpen &&
                    'rotate(135deg) translateY(-16px) translateX(-15px)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
              <line
                x2="27"
                transform="translate(0 16)"
                fill="none"
                strokeLinecap="round"
                strokeWidth="2"
                sx={{
                  transform:
                    isOpen &&
                    'rotate(135deg) translateY(-16px) translateX(-15px)',
                  opacity: isOpen ? 0 : 1,
                  transition: 'all 0.2s ease-in-out',
                }}
              />
            </g>
          </g>
        </svg>
      </button>
      <Flex
        sx={{
          position: ['fixed'],
          flexDirection: ['column'],
          bg: ['#104883'],
          width: '100%',
          height: ['105vh', null, null, 'auto'],
          zIndex: 9,
          left: 0,
          top: isOpen ? 0 : '-100vh',
          opacity: isOpen ? 1 : 0,
          display: ['flex', null, null, 'none'],
          pt: 200,
          gap: 3,
          px: 4,
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <Logo type="white" size={150} />
        <br />
        {routes.map((route: Route, i: number) => (
          <Link
            to={route.path}
            key={i}
            sx={{
              color: ['white', null, null, 'black'],
              fontWeight: 'medium',
              fontSize: [32, null, null, 16],
            }}
          >
            {route.name}
          </Link>
        ))}
        <Link
          to="/privacy-policy"
          sx={{
            color: 'white',
            fontWeight: 300,
            mt: 3,
          }}
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-use"
          sx={{
            color: 'white',
            fontWeight: 300,
          }}
        >
          Terms of Use
        </Link>
        <a
          href="https://irdirect.net/CURO/whistleblower_iframe?template=CURO"
          sx={{
            color: 'white',
            fontWeight: 300,
          }}
        >
          Ethics Hotline
        </a>
      </Flex>
    </Flex>
  )
}

export default Nav
